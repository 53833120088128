<template>    
  <v-dialog max-width="800px" v-model="dialog">
    <v-card style="height:calc(100vh - 200px);">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          AP 선택
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>          
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-0 mt-6"  
            label="Search"
            placeholder="검색어를 입력하세요."            
            Regular
            persistent-placeholder
            ref="NowSearchKeyWordRef"
            v-on:keyup.enter="Search()"
          ></v-text-field>        
          <v-btn  text icon  
          class="ml-n9"                
            @click="Search()"            
          ><v-icon>search</v-icon>
          </v-btn>  
          <v-switch
            class="mt-6 ml-2"
            v-model="bNotRegOnly"
            label="미등록만 조회"
          ></v-switch>
        </v-toolbar>
        <v-divider></v-divider>      
      <v-data-table :items-per-page="-1" fixed-header         
        :headers="headers" 
        :items="Records" 
        item-key="cApId"                   
        height="calc(100vh - 345px)"
        @dblclick:row="handleDoubleClick">        
        <template v-slot:item.cSel="{ item }">                    
          <v-icon big @click="selectItem(item)">mdi-check-circle</v-icon>
        </template>
        <template v-slot:item.cMapFullNm="{ item }">
          <v-chip v-if="item.cMapId == 'NN'" label small color="red lighten-2" dark>미정</v-chip>
          <v-chip v-else label small color="grey" dark>{{item.cMapFullNm}}</v-chip>
        </template>

        <template v-slot:item.cInOut="{ item }">
          <v-chip v-if="item.cInOut == 'NONE' " small label color="grey lighten-3">일반</v-chip>
          <v-chip v-if="item.cInOut == 'IN' " small label color="red lighten-2" dark>IN</v-chip>
          <v-chip v-if="item.cInOut == 'OUT' " small color="blue lighten-2" label dark>OUT</v-chip>
        </template>  

        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="grey darken-2" text @click="closePage()"
          ><v-icon class="mr-2">mdi-close</v-icon>취소</v-btn>
        <!-- <v-btn color="blue darken-1" text @click="SelectData()"
          ><v-icon class="mr-2">check_circle</v-icon>확인</v-btn> -->
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
// import Util from "../Util.js";
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';


export default {
  components: {    
  },
  data() {
    return {
      dialog: false,
      sDate : "",      
      model : null,      
      selected: [],
      bNotRegOnly : true,
      cNowSearchKeyWord : "",
      headers: [        
        // { class: "font-weight-bold subtitle-2",  text: "아이디", value: "cApId", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "선택", value: "cSel", width: 80, sortable: false },     
        { class: "font-weight-bold subtitle-2",  text: "AP", value: "cApId", width: 80 },      
        { class: "font-weight-bold subtitle-2",  text: "MAC", value: "cMac", width: 150 },      
        { class: "font-weight-bold subtitle-2",  text: "장비타입", value: "cInOut", width: 100 },
        { class: "font-weight-bold subtitle-2",  text: "전원타입Id", value: "cPowerId", width: 100 },
        { class: "font-weight-bold subtitle-2",  text: "배정", value: "cMapFullNm", width: 170 },
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage: function() {                
      this.dialog = false;
    },


    handleDoubleClick(value, items) { 
      console.log(value, items);
      this.selectItem(items.item);          
    },

    selectItem(item) {
      this.$emit("onSelect", {cApId : item.cApId, cType : "AP", cMac : item.cMac});
      this.dialog = false;  
    },
    Show: function() {             
      this.selected = [];
      this.Records = [];      
      this.cNowSearchKeyWord = "";
      this.dialog = true;      

      setTimeout(() => {
        // this.$refs.NowSearchKeyWordRef.focus();  
        this.Search();
      }, 100);      
    },
    
    Search(){
      this.Records = [];

      var cNotRegOnly = "Y";

      if (!this.bNotRegOnly) { cNotRegOnly = "N"; }

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cNowSearchKeyWord : this.cNowSearchKeyWord,    
        cNotRegOnly : cNotRegOnly,
        cUseOnly : "Y",        
      };
      axios
        .post(BasicInfo.UIL_API + "GetApSel", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;
            for(var i = 0; i < this.Records.length; i++){
              this.Records[i].bUse = this.Records[i].cUse == "Y";              
            }            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },
  }
};
</script>
