<template>  
  <div class="background_basic">            
    <v-card class="ma-3">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-map-marker-radius</v-icon>AP위치
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>        
        <v-spacer></v-spacer>
      <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="blue accent-4"
      ></v-progress-linear>  
      </v-toolbar>
    <v-divider></v-divider>          

    <v-row class="ma-0">        
      <v-col cols="12" sm="3" md="3">
        <v-card flat outlined height="calc(100vh - 180px)" class="mt-0 pa-0">               
            <v-btn-toggle v-model="toggle_one" mandatory class="ma-1">
              <v-btn>
                <v-icon>mdi-access-point</v-icon>
              </v-btn>
              <v-btn>
                <v-icon>mdi-map-marker</v-icon>
              </v-btn>
              <v-btn>
                <v-icon>mdi-crane</v-icon>
              </v-btn>
          </v-btn-toggle>         
          
          <v-divider></v-divider>       
          <div style="overflow-x: auto; overflow-y: auto; width:100%; height: calc(100vh - 241px); padding:10px; background-color:#ffffff">
            <v-treeview     
                :active="selection"                
                :items="items"
                activatable
                item-key="id"
                hoverable
                selection-type="independent"
                dense  
                :open.sync="open"
                @update:active="onUpdate"
                return-object>
                <!-- <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.iLev == -1 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 0 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 1 ">mdi-group</v-icon>
                  <v-icon v-else-if="item.iLev == 2 ">mdi-office-building</v-icon>
                  <v-icon v-else>mdi-floor-plan</v-icon>
                </template> -->
            </v-treeview>
          </div>
        </v-card>
      </v-col>  

      <v-col cols="12" sm="9" md="9">
        <v-card height="calc(100vh - 180px)" flat outlined class="mt-0 pa-0" v-resize="onResize">
          <v-card-title v-if = "sNowMapId == ''" class="justify-center">맵을 선택하세요.</v-card-title>
          <div v-else>  
          <!-- style="height: calc(100vh - 182px)" -->
            <vl-map :load-tiles-while-animating="false" :load-tiles-while-interacting="false" style="height: calc(100vh - 182px)"
              @click="ClickMap($event.coordinate)" >
              <vl-view :projection="projection" :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>
              <!-- <vl-layer-image id="xkcd"> -->
              <vl-layer-image id="">
                <vl-source-image-static :url="imgUrl" :size="imgSize" :extent="imgExtent" :projection="projection">
                </vl-source-image-static>                
                <vl-overlay v-for="(ApPos, i) in zApPos" :key="i" :offset="[-18, -22]" :position="[ApPos.x*fRateSize, ApPos.y*fRateSize]">            
                  <template >
                    <div class="overlay-content">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">   
                          <div v-if="zApPos[i].cType == 'AP'" >
                            <div v-if="zApPos[i].bSel == true">                            
                              <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-access-point-network</v-icon>                                     
                            </div>
                            <div v-else>
                              <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-access-point-network</v-icon>                                     
                            </div>  
                            <div style="font-size:14px;">{{ApPos.cMac.substring(8,12)}}</div>  
                          </div>
                          <div v-else-if="zApPos[i].cType == 'MAP'" >
                            <div v-if="zApPos[i].cBuildingType == 'N'">                            
                              <div v-if="zApPos[i].bSel == true">                            
                                <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                              </div>
                              <div v-else>
                                <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                              </div>  
                            </div>  
                            <div v-else>                            
                              <div v-if="zApPos[i].bSel == true">                            
                                <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                              </div>
                              <div v-else>
                                <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                              </div>  
                            </div>  
                          </div>
                          <div v-else >
                            <div v-if="zApPos[i].bSel == true">                              
                              <img class="mt-1 mr-2" v-on="on" height="30" :src="require('@/assets/Device/D'+zApPos[i].cDeviceTypeId+'.svg')" @click="SelectPoi(ApPos.cApId, ApPos.cType)"/>                                 
                            </div>
                            <div v-else>                              
                              <img class="mt-1 mr-2" v-on="on" height="30" :src="require('@/assets/Device/D'+zApPos[i].cDeviceTypeId+'.svg') " style="filter: opacity(0.5) drop-shadow(0 0 0 #000000);" @click="SelectPoi(ApPos.cApId, ApPos.cType)"/>                                 
                            </div>  
                            <!-- <div class="mt-n2" style="font-size:12px;">{{ApPos.cMac}}</div>   -->
                            <!-- <div v-if="zApPos[i].bSel == true">                            
                              <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                            </div>
                            <div v-else>
                              <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                            </div>   -->
                          </div>
                        </template>                    
                        <div>{{ApPos.cApId}} / {{ApPos.cMac}}</div>         
                        <div v-if="ApPos.cType=='AP'" class="mt-1 mb-1">                          
                          <v-chip v-if="ApPos.cInOut == 'NONE' " small label color="grey lighten-3">일반</v-chip>
                          <v-chip v-else-if="ApPos.cInOut == 'IN' " small label color="red lighten-2" dark>IN</v-chip>
                          <v-chip v-else-if="ApPos.cInOut == 'OUT' " small color="blue lighten-2" label dark>OUT</v-chip>
                          <v-chip v-else small label color="grey lighten-3">일반</v-chip>                                           
                        </div>
                      </v-tooltip>  
                    </div> 
                  </template>
                </vl-overlay>                  
              </vl-layer-image> 
            </vl-map>        
          </div>
          <v-fab-transition>
            <v-btn
              v-show="cNowApId != ''"
              color="black"
              dark
              absolute
              bottom
              small
              left
              fab 
              class="ml-0 mb-8"               
              @click="NoSelectPoi()"
            >
              <v-icon >mdi-cancel</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition>
            <v-btn
              v-show="cNowApId != ''"
              color="black"
              dark
              absolute
              small
              bottom
              left
              fab
              class="ml-14 mb-8"
              @click="DeletePoi()"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-fab-transition>

        </v-card>          
      </v-col>  
    </v-row>
    </v-card>   
    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />        
    <MsgBox ref="MsgBoxRef2" @onMsgBox="onMsgBoxFunc2" />        
    <ApSelect ref="ApSelectRef" @onSelect="onSelectAp" />      
    <GetSelMapOne ref="GetSelMapOneRef" @onGetMap="onGetMap" />       
    <UserSelect ref="UserSelectRef" @onSelectUser="onSelectUser" />       
    
  </div>
</template>

<script>
import MsgBox from "@/components/MsgBox.vue";
import ApSelect from "@/components/ApSelect.vue";
import GetSelMapOne from "@/components/GetSelMapOne.vue";
import UserSelect from "@/components/UserSelect.vue";
// import GetSelMap from "@/components/GetSelMap.vue";
import BasicInfo from "../BasicInfo.js";
// import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import EventBus from '@/eventBus.js';
import { createProj, addProj } from 'vuelayers/lib/ol-ext';


let size = [2048, 2048];
let extent = [0, 0, ...size];


let customProj = createProj({
  code: 'xkcd-image',
  units: 'pixels',
  extent,
})




addProj(customProj);

export default {
  components: {
    MsgBox,        
    ApSelect,
    GetSelMapOne,
    UserSelect,
  },
  data: () => ({  
    toggle_one : 0,
    selection : [],    
    fRateSize : 1,
    sNowBuildingId : "",
    sNowMapGroupId : "",
    sNowMapId : "",
    sFloor : "0",
    sSelBuildingId : "",
    sSelMapGroupId : "",
    sSelMapId : "",
    loading : false,
    Records: [],
    items: [],        
    cNowApId : "",    
    cNowType : "AP",
    zoom: BasicInfo.Map_Zoom_Default,
    center: [size[0] / 2, size[1] / 2],
    rotation: 0,
    imgSize: size,    
    imgExtent: extent,
    nowPOS : {
      left : 0, top : 0
    },
    projection: customProj.getCode(),
    imgUrl : BasicInfo.FILE_API + "Files/NoImage.svg",
    zApPos: [],    
    open: [],
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),    
  },

  created() {
    this.initialize();
  },  

  mounted () {
    window.addEventListener("keydown", this.onKeyDown, false);     
    this.onResize();
  },

  methods: {
    initialize() {  
      this.Search();    
    },
    
    onKeyDown(event){             
      if (event.code == "Escape") {
        this.NoSelectPoi();
      }
      if (event.code == "Delete") {
        if (this.cApId != "") {
          this.DeletePoi();
        }        
      }
    },

    onResize () {
      size = [window.innerWidth, window.innerWidth];
      extent = [0, 0, ...size];

      this.imgSize = size;
      this.imgExtent = extent;
      this.center = [size[0] / 2, size[1] / 2];

      this.fRateSize = window.innerWidth/2048;

      this.zoom = 1;
    },
    onUpdate(selection) {                  
      this.cNowType = "AP";
      this.sNowMapId = "";
      this.sNowBuildingId = "";
      this.sNowMapGroupId = "";
      this.sFloor = "";
      this.zApPos = [];
      if (selection.length > 0) {
        this.imgUrl = selection[0].Path;    
        this.sNowMapGroupId = selection[0].cMapGroupId;        
        this.sNowBuildingId = selection[0].cBuildingId;        
        this.sNowMapId = selection[0].cMapId;        
        this.sFloor = selection[0].cFloor;        
        this.getApPos();
      }
    },

    NoSelectPoi() {
      this.cNowApId = "";
      this.cNowType = "";
      this.sSelMapGroupId = "";
      this.sSelBuildingId = "";
      this.sSelMapId = "";

      for(var i = 0; i < this.zApPos.length; i++){
        this.zApPos[i].bSel = false;                              
      }
    },

    DeletePoi() {      
      this.$refs.MsgBoxRef2.RunMsgBoxShow(
        "확인",        
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.cNowType + "/" + this.cNowApId +          
          "]"
      );
    },

    onMsgBoxFunc2(result) {
      if (result === true) {
          var posData = {
            cUserId: this.$store.state.UserInfo.UserId,
            cApId: this.cNowApId,
            cType: this.cNowType,
            cMapId: this.sNowMapId,
            cBuildingId: this.sNowBuildingId,
            cMapGroupId: this.sNowMapGroupId,
            cSelMapId: this.sSelMapId,
            cSelBuildingId: this.sSelBuildingId,
            cSelMapGroupId: this.sSelMapGroupId,
            cDel : "Y",
            x : 0,
            y : 0,
          };        
        
        axios
          .post(BasicInfo.UIL_API + "SetApMap", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {                            
              for(var i = 0; i < this.zApPos.length; i++){                                
                if (this.zApPos[i].cApId == this.cNowApId) {
                  this.zApPos.splice(i, 1);
                  this.cNowApId = "";
                  this.sSelMapGroupId = "";
                  this.sSelBuildingId = "";
                  this.sSelMapId = "";                 
                  return;
                }
              }
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
        }
    },  
    
    
    getApPos(){
      this.cNowApId = "";
      this.sSelMapGroupId = "";
      this.sSelBuildingId = "";
      this.sSelMapId = "";
      this.zApPos = [];
      var posData = {    
        cMapId : this.sNowMapId,
        cMapGroupId : this.sNowMapGroupId,
        cBuildingId : this.sNowBuildingId,
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      
      axios
        .post(BasicInfo.UIL_API + "GetApPos", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }          
          
          if (res.data.ResultCd === "00") {                  
            for (let i = 0; i < res.data.Result.length; i++) {
              var item = {
                cApId: res.data.Result[i].cApId,
                cMapGroupId : res.data.Result[i].cMapGroupId,
                cBuildingId : res.data.Result[i].cBuildingId,
                cMapId : res.data.Result[i].cMapId,
                x : res.data.Result[i].x,
                y : res.data.Result[i].y,
                cPosistion : res.data.Result[i].cPosistion,
                cMac : res.data.Result[i].cMac,
                cType : res.data.Result[i].cType,
                cInOut : res.data.Result[i].cInOut,
                cBuildingType : res.data.Result[i].cBuildingType,
                cDeviceTypeId : res.data.Result[i].cDeviceTypeId,
                bSel : false,                
              }
              this.zApPos.push(item);
            }
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },

    
    ClickMap(data) {
      if (this.sNowMapId == "") {
         EventBus.$emit("onShowMsg", true, "맵을 선택하세요.");
        return;
      }
      
      this.nowPOS.left = parseInt(data[0]) / this.fRateSize;
      this.nowPOS.top = parseInt(data[1]) / this.fRateSize;
  

      if (this.cNowApId == "") {
        for(var i = 0; i < this.zApPos.length; i++){
          this.zApPos[i].bSel = false;                      
        }
        let cType = "AP를";        

        this.cNowType = "AP";

        if (this.toggle_one == 1) {
          cType = "구조물을";
          this.cNowType = "MAP";          
        }

        if (this.toggle_one == 2) {
          cType = "자원을";
          this.cNowType = "DEVICE";          
        }
        
        this.$refs.MsgBoxRef.RunMsgBoxShow("확인", cType + " 등록하시겠습니까?");
        return;
      }

      var ZSel = {
        cApId : this.cNowApId,     
        cMapGroupId : this.sNowMapGroupId,
        cBuildingId : this.sNowBuildingId,
        cMapId : this.sNowMapId,
        cSelMapGroupId : this.sSelMapGroupId,
        cSelBuildingId : this.sSelBuildingId,
        cSelMapId : this.sSelMapId,
        cType : this.cNowType,     
        cFloor : this.sFloor,     
      };
      this.onSelect(ZSel);
    },

    onMsgBoxFunc(result) {
      if (result === true) {      
        if (this.toggle_one == 0) {
          this.$refs.ApSelectRef.Show();
        } else if (this.toggle_one == 1) {
          this.$refs.GetSelMapOneRef.show();     
        } else  {
          this.$refs.UserSelectRef.Show();     
        }        
      }
    },    

  
    onGetMap(select) {      
      var ZSel = {
        cApId : select.cMapGroupId + select.cBuildingId + select.cMapId,
        cSelMapGroupId : select.cMapGroupId,
        cSelBuildingId : select.cBuildingId,
        cSelMapId : select.cMapId,
        cMapGroupId : this.sNowMapGroupId,
        cBuildingId : this.sNowBuildingId,
        cMapId : this.sNowMapId,
        cType : "MAP",     
        cFloor : this.sFloor,     
        cMac : select.cMapNm,
      };            
      this.onSelect(ZSel);
    },

    onSelectAp(ZSel){
      ZSel.cMapGroupId = this.sNowMapGroupId;
      ZSel.cBuildingId = this.sNowBuildingId;
      ZSel.cMapId = this.sNowMapId;      
      ZSel.cFloor = this.sFloor;      

      this.onSelect(ZSel);
    },

    onSelect(ZSel) {                  
      if (ZSel.cType == 'DEVICE') {
        this.onSelectUserFunc(ZSel);
        return;
      } 
      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cApId: ZSel.cApId,
        cMapGroupId : ZSel.cMapGroupId,
        cBuildingId : ZSel.cBuildingId,
        cMapId : ZSel.cMapId,
        cSelMapGroupId : ZSel.cSelMapGroupId,
        cSelBuildingId : ZSel.cSelBuildingId,
        cSelMapId : ZSel.cSelMapId,
        cType: ZSel.cType,        
        cFloor: ZSel.cFloor,        
        x : this.nowPOS.left,
        y : this.nowPOS.top,
        cDel : "N",
      };      
      
      axios
        .post(BasicInfo.UIL_API + "SetApMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.cNowApId = ZSel.cApId;
            this.cNowType = ZSel.cType;
            
            for(var i = 0; i < this.zApPos.length; i++){
              this.zApPos[i].bSel = false;   
              if (this.zApPos[i].cApId == ZSel.cApId) {
                this.zApPos[i].bSel = true;     
                this.zApPos[i].x = this.nowPOS.left;
                this.zApPos[i].y = this.nowPOS.top;
                return;
              }
            }

            var item = {
              cApId: ZSel.cApId,             
              cType : ZSel.cType,
              x : this.nowPOS.left,
              y : this.nowPOS.top,
              cPosistion : ZSel.cPosistion,
              cMac : ZSel.cMac,              
              bSel : true,                
            }

            if (ZSel.cType == 'MAP') {
              item.cMapGroupId = ZSel.cSelMapGroupId;
              item.cBuildingId = ZSel.cSelBuildingId;
              item.cMapId = ZSel.cSelMapId;


              this.sSelMapGroupId = ZSel.cSelMapGroupId;
              this.sSelBuildingId = ZSel.cSelBuildingId;
              this.sSelMapId = ZSel.cSelMapId;
            }
            this.zApPos.push(item);
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    SelectPoi(Id){      
      for(let i = 0; i < this.zApPos.length; i++){
        if (this.zApPos[i].cApId == Id){
          if (this.zApPos[i].bSel) {
            this.zApPos[i].bSel = false;    
            this.cNowApId = "";
            this.sSelMapGroupId = "";
            this.sSelBuildingId = "";
            this.sSelMapId = "";
            return;
          }          
        }                
      }


      for(let i = 0; i < this.zApPos.length; i++){
        this.zApPos[i].bSel = false;                    
      }

      for(let i = 0; i < this.zApPos.length; i++){        
        if (this.zApPos[i].cApId == Id){
          this.zApPos[i].bSel = true;              
          this.cNowApId = Id;
          this.sSelMapGroupId = this.zApPos[i].cMapGroupId;
          this.sSelBuildingId = this.zApPos[i].cBuildingId;
          this.sSelMapId = this.zApPos[i].cMapId;
          this.cNowType = this.zApPos[i].cType;

          return;
        }         
      }
    },
    
    
    Search() {         
      this.open = [];   
      this.items = [];
      this.selection = [];
      this.sNowMapId = "";
      this.zApPos = [];
      this.imgUrl = BasicInfo.FILE_API + "Files/NoImage.svg";

      var posData = {        
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {                        
            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 0) {
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cFloor: res.data.Result[i].cFloor,                
                  cParentNm : "",
                  children: [],
                }
                this.items.push(item);           
                
                this.open.push(item);
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 1) {                
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cFloor: res.data.Result[i].cFloor,                
                  cParentNm : this.items[0].name,
                  children: [],
                }
                this.items[0].children.push(item);   
                this.open.push(item);                      
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {                  
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    let item = {
                      id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                      cMapGroupId: res.data.Result[i].cMapGroupId,
                      cBuildingId: res.data.Result[i].cBuildingId,
                      cMapId: res.data.Result[i].cMapId,
                      name: res.data.Result[i].cMapNm,
                      file: res.data.Result[i].cFileId,
                      iLev: res.data.Result[i].iLev,
                      Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                      Order: res.data.Result[i].cOrder,             
                      cFloor: res.data.Result[i].cFloor,                   
                      cParentNm : this.items[0].children[j].name,
                      children: [],
                    }
                    this.items[0].children[j].children.push(item);
                    break;
                  }
                }
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 3) {
                for (let j = 0; j < this.items[0].children.length; j++) {    
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    for (let z = 0; z < this.items[0].children[j].children.length; z++) {    
                      if (this.items[0].children[j].children[z].cBuildingId == res.data.Result[i].cBuildingId) {     
                        let item = {
                          id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                          cMapGroupId: res.data.Result[i].cMapGroupId,
                          cBuildingId: res.data.Result[i].cBuildingId,
                          cMapId: res.data.Result[i].cMapId,
                          name: res.data.Result[i].cMapNm,
                          file: res.data.Result[i].cFileId,
                          iLev: res.data.Result[i].iLev,
                          Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                          Order: res.data.Result[i].cOrder,           
                          cFloor: res.data.Result[i].cFloor,                
                          cParentNm : this.items[0].children[j].children[z].name,     
                          children: [],
                        }
                        this.items[0].children[j].children[z].children.push(item);
                        break;
                      }
                    }
                  }
                }
              }              
            }
            
            if (this.items.length > 0) {                      
              this.selection.push(this.items[0]);     

              this.sNowMapGroupId = res.data.Result[0].cMapGroupId;
              this.sNowBuildingId = res.data.Result[0].cBuildingId;
              this.sNowMapId = res.data.Result[0].cMapId;
              this.imgUrl = BasicInfo.FILE_API + res.data.Result[0].cPath;   
            }     
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },


    onSelectUser(ZSel){
      ZSel.cMapGroupId = this.sNowMapGroupId;
      ZSel.cBuildingId = this.sNowBuildingId;
      ZSel.cMapId = this.sNowMapId;      
      ZSel.cFloor = this.sFloor;
      this.onSelectUserFunc(ZSel);
    },

    onSelectUserFunc(ZSel) {                  
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cApId: ZSel.cApId,
        cMapGroupId : ZSel.cMapGroupId,
        cBuildingId : ZSel.cBuildingId,
        cMapId : ZSel.cMapId,
        cSelMapGroupId : ZSel.cSelMapGroupId,
        cSelBuildingId : ZSel.cSelBuildingId,
        cSelMapId : ZSel.cSelMapId,
        cType: ZSel.cType,        
        cFloor: ZSel.cFloor,        
        x : this.nowPOS.left,
        y : this.nowPOS.top,        
        cDel : "N",
      };      
      
      axios
        .post(BasicInfo.UIL_API + "SetUserMap2", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.cNowApId = ZSel.cApId;
            this.cNowType = ZSel.cType;
            
            for(var i = 0; i < this.zApPos.length; i++){
              this.zApPos[i].bSel = false;   
              if (this.zApPos[i].cApId == ZSel.cApId) {
                this.zApPos[i].bSel = true;     
                this.zApPos[i].x = this.nowPOS.left;
                this.zApPos[i].y = this.nowPOS.top;
                return;
              }
            }

            var item = {
              cApId: ZSel.cApId,             
              cType : "DEVICE",
              cDeviceTypeId : ZSel.cDeviceTypeId,
              x : this.nowPOS.left,
              y : this.nowPOS.top,
              cPosistion : ZSel.cPosistion,
              cMac : ZSel.cMac,              
              bSel : true,                
            }

           
            this.zApPos.push(item);
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

  }
};
</script>

